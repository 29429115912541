<template>
    <div
        ref="formStep"
        class="form-step"
    >
        <Component
            :is="fieldComponent(field)"
            v-for="(field, fieldIndex) in props.step.fields"
            :key="fieldIndex + 1"
            :field="field"
            :step="props.index"
            :product-id="props.productId"
            @submit-step="submitStep(props.productId)"
        />
    </div>
</template>

<script setup>
import { defineAsyncComponent, onBeforeMount, onMounted, ref } from 'vue';
import { useFormStore } from '../../js/stores/form';
import submitStep from './utils/submit-step';

const emit = defineEmits(['updateFormHeight']);
const props = defineProps({
    step: {
        type: Object,
        required: true,
    },
    index: {
        type: Number,
        required: true,
    },
    productId: {
        type: [String, Number],
        required: true,
    },
});

const formStore = useFormStore(props.productId);

const formStep = ref(null);

// Dynamically load the correct step file
const fieldComponent = field => {
    const fieldType = field.option.replaceAll('_', '-');
    return defineAsyncComponent(() => import(`./elements/watson-form-${fieldType}.vue`).catch(() => import('./elements/watson-form-not-found.vue')));
};

const updateFormHeight = () => {
    setTimeout(() => {
        emit('updateFormHeight', formStep.value.clientHeight);
    }, 300);
};

onBeforeMount(() => {
    formStore.initializeStep(props.index);
});

onMounted(() => {
    updateFormHeight();
});
</script>
