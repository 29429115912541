<template>
    <div class="form-header">
        <span class="form-header__title">{{ props.title }}</span>
    </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        required: false,
        default: '',
    },
});
</script>

<style lang="scss">
@use 'watson-theme-form-header';
</style>
