<template>
    <div
        class="form"
        :class="alternativeProgress ? 'form--alternative-progress' : ''"
    >
        <WatsonThemeFormHeader
            :title="form.header"
        />

        <WatsonFormGenerator
            v-if="state.form"
            :form="props.form"
        />

        <WatsonThemeMarketingConsent
            v-if="state.marketingConsent"
            :form="props.form"
        />

        <WatsonThemeVerification
            v-if="state.verification"
        />

        <WatsonThemeFormCompletion
            v-if="state.thankYou"
            :form="props.form"
        />

        <WatsonThemeFormSubmit
            v-if="state.form"
            :form="props.form"
        />

        <WatsonThemeFormFooter
            v-if="state.form"
            :form="props.form"
        />

        <WatsonThemeFormProgressProcess
            v-if="alternativeProgress === true"
            :form="props.form"
        />

        <WatsonThemeFormProgress
            v-else
            :form="props.form"
        />
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import { storeToRefs } from 'pinia';

import WatsonThemeFormHeader from './partials/form-header/watson-theme-form-header.vue';
import WatsonFormGenerator from '@form/watson-form-generator.vue';
import WatsonThemeFormSubmit from './partials/form-submit/watson-theme-form-submit.vue';
import WatsonThemeFormFooter from './partials/form-footer/watson-theme-form-footer.vue';
import WatsonThemeFormProgress from './partials/form-progress/watson-theme-form-progress.vue';
import WatsonThemeFormCompletion from './steps/form-completion/watson-theme-form-completion.vue';
import { useFormStore } from '../../../../js/stores/form';
import WatsonThemeVerification from './steps/watson-theme-verification/watson-theme-verification.vue';
import WatsonThemeMarketingConsent from './steps/form-marketing-consent/watson-theme-marketing-consent.vue';
import WatsonThemeFormProgressProcess from './partials/form-progress-process/watson-theme-form-progress-process.vue';

const page = usePage();

const alternativeProgress = page.props.CroProgessBar;

const props = defineProps({
    form: {
        type: Object,
        required: false,
        default: () => {},
    },
});

const formStore = useFormStore(props.form.product_id);
const { state } = storeToRefs(formStore);
</script>

<style lang="scss">
@use 'watson-theme-form';
</style>
