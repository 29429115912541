import { getNextQuestion } from './get-next-question';

export default steps => {
    const allAnswers = [];

    steps.forEach((step, index) => {
        const answers = [];

        step.fields.forEach(field => {
            switch (field.option) {
                case 'autocomplete_address':
                case 'text':
                case 'html':
                case 'checkbox':
                    getSingleFieldQuestionAnswer(field);
                    break;
                case 'dropdown':
                case 'radio_sliding':
                case 'buttons':
                case 'multiselect_expanded':
                case 'redirect':
                    getRepeaterQuestionAnswers(field);
                    break;
            }
        });

        function getRepeaterQuestionAnswers(field) {
            field.quote_forms_steps_fields_options.forEach(option => {
                answers.push({
                    next_question: {
                        value: option.quote_forms_steps_fields_options_item_next_step,
                    },
                });
            });
        }

        function getSingleFieldQuestionAnswer(field) {
            answers.push({
                next_question: {
                    value: field.quote_forms_steps_fields_next_step,
                },
            });
        }

        allAnswers[index] = {
            uuid: index + 1,
            answers: [],
        };

        const allStepAnswers = [];
        for (const answer of answers) {
            const nextQuestion = getNextQuestion(steps, answer.next_question.value, index + 1);

            allStepAnswers.push({
                next_step: nextQuestion,
            });
        }

        allAnswers[index].answers = [...new Map(allStepAnswers.map(item => [item.next_step, item])).values()];
    });

    return allAnswers;
};
