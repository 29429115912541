<template>
    <div
        class="form-progress"
    >
        <div class="form-progress__inner"></div>
        <span
            class="form-progress__label"
        >{{ Math.round((currentStep / steps) * 100) }}%</span>
        <div class="form-progress__text">
            <p>Free quotes</p>
        </div>
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useFormStore } from '../../../../../../js/stores/form';

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
});

const formStore = useFormStore(props.form.product_id);
const { step, totalSteps, state } = storeToRefs(formStore);

const currentStep = computed(() => {
    let currentStep = step.value;

    if (state.value.thankYou) currentStep += 1;

    return currentStep;
});

const steps = computed(() => totalSteps.value + 1);
</script>

<style lang="scss" scoped>
@use 'watson-theme-form-progress-process';
.form-progress {
  --total-steps: v-bind(steps);
  --current-step: v-bind(currentStep)
}
</style>
