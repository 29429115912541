import { usePage } from '@inertiajs/vue3';
import { useFormStore } from '../../../js/stores/form';

export default form => {
    const page = usePage();
    const formStore = useFormStore(form.product_id);
    const { initializeField, getFieldData, setFieldData } = formStore;

    const textSystemKeys = [];
    for (const step of form.steps) {
        for (const field of step.fields) {
            if (field.option === 'text') {
                textSystemKeys.push(field.system_key);
            }
        }
    }

    const variables = {
        websiteId: 'test',
        country: '',
        language: '',
        timezone: '',
        leadsService: 'sherlock',
        enquiredDashboardEnabled: false,
        pageUrl: page.url,
        textKeys: textSystemKeys,
        zoeRedirect: '',
    };

    for (const [key, value] of Object.entries(variables)) {
        const snakeKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

        initializeField(0, { system_key: key });

        setFieldData(key, 'value', form[snakeKey] ? form[snakeKey] : value);
    }

    if (!getFieldData('aasii', 'value')) {
        initializeField(0, {
            system_key: 'aasii',
            value: form['aasii'] || '',
        });
    }

    formStore.enquirerDashboard = form.enquirer_dashboard_enabled;
};
