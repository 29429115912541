<template>
    <div
        class="verification"
    >
        <p class="verification__label">
            Please enter the verification code send to you by SMS.
        </p>
        <input
            ref="verifyCodeInput"
            v-model="verifyCode"
            class="verification__input"
            type="text"
            autocomplete="one-time-code"
            minlength="6"
            maxlength="6"
            pattern="\d+"
        >
        <div
            v-if="verifyCodeError"
            class="verification__error"
        >
            {{ verifyCodeError }}
        </div>
        <button
            class="verification__button"
            @click="submitVerifyCode"
        >
            Verify
        </button>
    </div>
</template>

<script setup>

import axios from 'axios';
import { ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { storeToRefs } from 'pinia';
import { useFormStore } from '../../../../../../js/stores/form';
import gtag from '@form/utils/gtag';

const page = usePage();
const formStore = useFormStore();
const { verification, state } = storeToRefs(formStore);

const translations = page.props.settings.translations.quoteForm;
const verifyCodeInput = ref('');
const verifyCode = ref('');
const verifyCodeError = ref('');

function submitVerifyCode() {
    verifyCodeError.value = '';

    axios.post('/verify-code', {
        code: verifyCode.value,
        phone: verification.value.phone,
        hash: verification.value.hash,
    },
    )
        .then(({ data }) => {
            if (!data.phone_number_verified) {
                verifyCodeError.value = translations.errorCodeInvalid;
                verifyCode.value = '';
                verifyCodeInput.value.focus();

                return;
            }

            gtag({
                event: 'Phone Verification',
                event_category: 'Verified',
            });

            state.value.verification = false;
            state.value.thankYou = true;
        })
        .catch(() => {
            verifyCodeError.value = translations.errorCodeInvalid;
            verifyCode.value = '';
            verifyCodeInput.value.focus();
        });
}

</script>

<style lang="scss" scoped>
@use 'watson-theme-verification';
</style>
