<template>
    <div
        class="marketing-consent"
    >
        <!-- eslint-disable vue/no-v-html -->
        <div
            class="marketing-consent__title"
            v-html="content.title"
        ></div>
        <img
            v-if="content.image"
            class="marketing-consent__image"
            :src="content.image"
        >
        <div class="marketing-consent__button-container">
            <button
                class="marketing-consent__button"
                @click="submitConsent(true)"
                v-html="content.buttonYes"
            ></button>
            <div
                class="marketing-consent__text"
                v-html="content.text"
            ></div>
        </div>

        <button
            class="marketing-consent__url-button"
            @click="submitConsent(false)"
            v-html="content.buttonNo"
        ></button>
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { useFormStore } from '../../../../../../js/stores/form';
import { generateFormData } from '@form/utils/form-data';

const props = defineProps({
    form: {
        type: Object,
        required: false,
        default: () => {},
    },
});

const formStore = useFormStore(props.form.product_id);
const { state, quoteCode, verification } = storeToRefs(formStore);

const page = usePage();

const content = page.props.settings.quoteFormConsent;

function gtag(...parameters) {
    window.dataLayer.push(...parameters);
}

function submitConsent(consented) {
    const formData = generateFormData(props.form.product_id);
    state.value.marketingConsent = false;

    if (verification.value.hash) {
        state.value.verification = true;
    } else {
        state.value.thankYou = true;
    }

    axios.post('/quote-form-consent',
        {
            ...{
                consented: consented,
                quotecode: quoteCode.value,
            },
            ...formData,
        },
    )
        .then(() => {
            gtag('event', 'Form Submit', {
                event_category: 'QuoteFormMarketingConsentCompleted',
                marketingConsent: consented ? 'yes' : 'no',
            });
        });
}

</script>

<style lang="scss" scoped>
@use "watson-theme-marketing-consent";
</style>
