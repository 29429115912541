import { useFormStore } from '../../../js/stores/form';

export const checkDependencies = (productId, step) => {
    const formStore = useFormStore(productId);
    const { getFieldData } = formStore;

    if (!step?.dependency_enabled) return true;

    const value = getFieldData(step.dependency_system_key, 'value');
    const allowedValues = step.dependency_values.split(',');

    return allowedValues.includes(value);
};
