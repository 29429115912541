import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { useFormStore } from '../../../js/stores/form';

export default (questions, formId) => {
    const formStore = useFormStore(formId);
    const { step, allFlows, currentFlows, equalFlow, totalFlowLength } = storeToRefs(formStore);

    allFlows.value = createAllFlows(questions[0], [], questions, []);
    currentFlows.value = allFlows.value;
    equalFlow.value = isEqualFlow(allFlows.value);
    totalFlowLength.value = calcTotalFlowLength(currentFlows.value);

    // Update question flow whenever a question is answered
    watch(() => step.value, () => {
        updateFlow(formId);
    });
};

const createAllFlows = (step, currentFlow = [], steps, flows) => {
    const flow = structuredClone(currentFlow);
    flow.push(steps.indexOf(step) + 1);

    step.answers.forEach(answer => {
        if (!answer.next_step) {
            flows.push(flow);
            return;
        }

        const nextStep = steps.find(question => question.uuid === answer.next_step);

        createAllFlows(nextStep, flow, steps, flows);
    });

    return flows;
};

const updateFlow = formId => {
    const formStore = useFormStore(formId);
    const { allFlows, questionFlow, currentFlows, totalFlowLength } = storeToRefs(formStore);

    currentFlows.value = allFlows.value.filter(flow => {
        for (let i = 0; i < questionFlow.value.length; i++) {
            if (flow[i] !== questionFlow.value[i]) return false;
        }

        return true;
    });

    totalFlowLength.value = calcTotalFlowLength(currentFlows.value);
};

const isEqualFlow = allFlows => {
    return allFlows.length === 1;
};

const calcTotalFlowLength = currentFlows => {
    return Math.max(...currentFlows.map(flow => flow.length));
};
