import { useFormStore } from '../../../js/stores/form';

export const addUrlVariables = formId => {
    const formStore = useFormStore(formId);
    const { initializeField } = formStore;

    const queryParams = new URLSearchParams(window.location.search);
    const variables = ['aaci', 'aacri', 'aasii'];

    for (const variable of variables) {
        if (queryParams.has(variable)) {
            initializeField(0, {
                system_key: variable,
                value: queryParams.get(variable),
            });
        }
    }
};
