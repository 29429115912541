<template>
    <form
        ref="formGenerator"
        class="form-generator"
        @submit.prevent
    >
        <Transition
            v-for="(formStep, index) in props.form.steps"
            :key="index"
            name="form-step-transition"
        >
            <WatsonFormStep
                v-if="step === index + 1 && matchesDependencies(index + 1, formStep)"
                :index="index + 1"
                :step="formStep"
                :product-id="formId"
                @update-form-height="updateFormHeight"
            />
        </Transition>
    </form>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';
import WatsonFormStep from './watson-form-step.vue';
import { useFormStore } from '../../js/stores/form';
import { addUrlVariables } from './utils/add-url-variables';
import { addProductId } from './utils/product-id';
import addQuoteFormData from './utils/add-quote-form-data';
import { checkDependencies } from './utils/check-dependencies';
import generateAllFlows from './utils/generate-all-flows';
import getAllAnswers from './utils/get-all-answers';

const props = defineProps({
    form: {
        type: Object,
        required: false,
        default: () => {},
    },
});

const formId = ref(props.form.product_id);
const formStore = useFormStore(formId.value);

const { step, previousStep, state } = storeToRefs(formStore);
const { initializeStep, initializeTotal, setEnquiryDashboard } = formStore;

const formGenerator = ref(null);
const formHeight = ref(0);

onBeforeMount(() => {
    initializeStep(0);
    addUrlVariables(formId.value);
    addProductId(props.form.product_id);
    addQuoteFormData(props.form);
    state.value.loading = false;
});

const updateFormHeight = height => {
    if (formHeight.value === 0) {
        formHeight.value = height;
        return;
    }

    formGenerator.value.style.height = `${formHeight.value}px`;

    setTimeout(() => {
        formGenerator.value.style.height = `${height}px`;
    }, 10);

    setTimeout(() => {
        formGenerator.value.style.height = '';
        formHeight.value = height;
    }, 400);
};

const matchesDependencies = (index, formStep) => {
    const matchesDependencies = checkDependencies(props.form.product_id, formStep);

    if (!matchesDependencies) {
        if (index > previousStep.value) {
            previousStep.value++;
            step.value++;
        } else {
            previousStep.value--;
            step.value--;
        }

        return false;
    }

    return true;
};

initializeTotal(props.form.steps.length);
setEnquiryDashboard(props.form.enquirer_dashboard_enabled);

if (props.form.quote_forms_advanced_flow) {
    state.advancedFlow = true;

    const answers = getAllAnswers(props.form.steps);
    generateAllFlows(answers, formId.value);
}
</script>
