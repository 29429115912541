<template>
    <section
        class="form-submit"
    >
        <div
            v-if="step === totalSteps && errors.submit"
            class="form-submit__error"
        >
            {{ translations.error }} {{ errors.submit }}
            <p v-if="errors.sherlock">
                {{ errors.sherlock }}
            </p>
        </div>

        <button
            class="form-submit__button"
            :class="submitClass"
            @click="submitStep(props.form.product_id, nextStep)"
        >
            <span v-if="!state.loading">
                {{ buttonLabel }}
            </span>

            <svg
                v-else
                class="loader"
                width="120"
                height="30"
                viewBox="0 0 120 30"
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
            >
                <circle
                    cx="15"
                    cy="15"
                    r="15"
                >
                    <animate
                        attributeName="r"
                        from="15"
                        to="15"
                        begin="0s"
                        dur="0.8s"
                        values="15;9;15"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="fill-opacity"
                        from="1"
                        to="1"
                        begin="0s"
                        dur="0.8s"
                        values="1;.5;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle
                    cx="60"
                    cy="15"
                    r="9"
                    fill-opacity="0.3"
                >
                    <animate
                        attributeName="r"
                        from="9"
                        to="9"
                        begin="0s"
                        dur="0.8s"
                        values="9;15;9"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="fill-opacity"
                        from="0.5"
                        to="0.5"
                        begin="0s"
                        dur="0.8s"
                        values=".5;1;.5"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle
                    cx="105"
                    cy="15"
                    r="15"
                >
                    <animate
                        attributeName="r"
                        from="15"
                        to="15"
                        begin="0s"
                        dur="0.8s"
                        values="15;9;15"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="fill-opacity"
                        from="1"
                        to="1"
                        begin="0s"
                        dur="0.8s"
                        values="1;.5;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </circle>
            </svg>
        </button>
    </section>
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { usePage } from '@inertiajs/vue3';
import { useFormStore } from '../../../../../../js/stores/form';
import submitStep from '@form/utils/submit-step';

const props = defineProps({
    form: {
        type: Object,
        required: false,
        default: () => {},
    },
});

const page = usePage();
const formStore = useFormStore(props.form.product_id);

const { step, totalSteps, nextStep, errors, state } = storeToRefs(formStore);

const translations = page.props.settings.translations.quoteForm;

const submitClass = computed(() => {
    if (step.value === totalSteps.value) {
        return { 'form-submit__button-submit': step.value === totalSteps.value };
    } else {
        return '';
    }
});

const buttonLabel = computed(() => {
    if (step.value === 1 && props.form.label_first) {
        return props.form.label_first;
    }

    if (step.value === totalSteps.value) {
        return props.form.label_submit ?? 'Submit';
    }

    return props.form.label_next ?? 'Next';
});
</script>

<style lang="scss" scoped>
@use 'watson-theme-form-submit';
</style>
