<template>
    <section
        class="form-completion"
    >
        <span class="form-completion__header">
            {{ props.form.completed_header }}
        </span>

        <!-- eslint-disable vue/no-v-html -->
        <div
            class="form-completion__content content"
            v-html="props.form.completed_content"
        ></div>

        <div
            v-if="props.form?.quote_forms_completed_links?.length > 0"
            class="form-completion__items"
            style="margin-bottom: 1rem"
        >
            <a
                v-for="link in props.form.quote_forms_completed_links"
                :key="link.quote_forms_completed_links_text"
                class="completed-content__item"
                :title="link.quote_forms_completed_links_text"
                :href="link.quote_forms_completed_links_url"
            >
                <img
                    v-if="link.quote_forms_completed_links_image"
                    :src="link.quote_forms_completed_links_image"
                    :alt="link.quote_forms_completed_links_text"
                    class="completed-content__image"
                >

                {{ link.quote_forms_completed_links_text }}
            </a>
        </div>
    </section>
</template>

<script setup>
const props = defineProps({
    form: {
        type: Object,
        required: false,
        default: () => {},
    },
});
</script>

<style lang="scss" scoped>
@use 'watson-theme-form-completion';
</style>
