<template>
    <div
        class="form-footer"
    >
        <div
            v-if="step !== totalSteps"
            class="form-footer__clock"
        >
            {{ translations.footerText }}

            <div class="form-footer__back-button-wrapper">
                <button
                    v-if="step !== 1"
                    class="form-footer__back-button"
                    @click="previousStep(props.form.product_id)"
                >
                    {{ props.form.label_back ?? 'Back' }}
                </button>
            </div>
        </div>

        <div
            v-else-if="step === totalSteps"
            class="form-footer__privacy"
        >
            <!-- eslint-disable vue/no-v-html -->
            <div
                v-if="!!props.form['privacy_text_overwrite']"
                class="form-footer__privacy-text"
                v-html="props.form.privacy_text_overwrite"
            ></div>

            <!-- eslint-disable vue/no-v-html -->
            <div
                v-else
                class="form-footer__privacy-text"
                v-html="translations.privacyText"
            ></div>

            <div class="form-footer__bottom">
                <a
                    v-if="privacyCookiesPolicyUrl"
                    :href="privacyCookiesPolicyUrl"
                    target="_blank"
                    class="form-footer__privacy-link"
                >
                    {{ translations.privacyCookiesPolicyText }}
                </a>

                <div class="form-footer__back-button-wrapper">
                    <button
                        v-if="step !== 1"
                        class="form-footer__back-button"
                        @click="previousStep(props.form.product_id)"
                    >
                        {{ props.form.label_back ?? 'Back' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { usePage } from '@inertiajs/vue3';
import { useFormStore } from '../../../../../../js/stores/form';
import previousStep from '@form/utils/previous-step';

const props = defineProps({
    form: {
        type: Object,
        required: false,
        default: () => {},
    },
});

const formStore = useFormStore(props.form.product_id);
const page = usePage();

const { step, totalSteps } = storeToRefs(formStore);

const translations = page.props.settings.translations.quoteForm;
const privacyCookiesPolicyUrl = page.props.settings.privacyCookiesPolicyUrl;
</script>

<style lang="scss" scoped>
@use 'watson-theme-form-footer';
</style>
