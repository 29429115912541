import { storeToRefs } from 'pinia';
import { useFormStore } from '../../../js/stores/form';

export default productId => {
    const formStore = useFormStore(productId);
    const { step, previousStep, questionFlow, savedSteps } = storeToRefs(formStore);

    if (step.value <= 1) {
        return;
    }

    previousStep.value = step.value;
    step.value = questionFlow.value.slice(-2)[0];

    if (questionFlow.value.length >= 2) {
        savedSteps.value.push(questionFlow.value.slice(-1)[0]);
        questionFlow.value.pop();
    }
};
