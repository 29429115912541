<template>
    <div
        class="form-progress"
    >
        <div class="form-progress__inner">
            <span
                v-if="step > 1 && equalFlow"
                class="form-progress__label"
            >{{ step }}/{{ totalSteps }}</span>
        </div>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { storeToRefs } from 'pinia';
import { useFormStore } from '../../../../../../js/stores/form';

const props = defineProps({
    form: {
        type: Object,
        required: false,
        default: () => {},
    },
});

const formStore = useFormStore(props.form.product_id);
const { step, totalSteps, equalFlow } = storeToRefs(formStore);
</script>

<style lang="scss" scoped>
@use 'watson-theme-form-progress';
.form-progress {
  --total-steps: v-bind(totalSteps);
  --current-step: v-bind(step)
}
</style>
